* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $body-font;
}

.App {
    height: 100vh;
    position: absolute;
    z-index: 999;
    width: 100vw;
    background-color: black;
    padding: 2rem;
    color: white;
    text-transform: uppercase;
    overflow: hidden;
    display: flex;

    &:before {
        content: "";
        position: fixed;
        left: 0;
        right: 0;
        z-index: -1;
        display: block;
        background-image: url('https://apod.nasa.gov/apod/image/2201/noirlab2204a.jpg');
        background-size: cover;
        width: 100vw;
        height: 100vh;
        padding: 0;
        -webkit-filter: blur(1rem);
        -moz-filter: blur(1rem);
        -o-filter: blur(1rem);
        -ms-filter: blur(1rem);
        filter: blur(1rem);
        background-color: black;
    }
}

.siteTitle {
    width: 100%;
    text-align: center;
    color: white;
    position: absolute;
}

.highlight {
    color: $highlight;
}

.pictureDate {
    color: white;
    text-align: center;

    h2 {
        color: $highlight;
    }

    h3 {
        font-size: 3rem;
    }

    border-top-left-radius: $radius;
}

.left {
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
}

a {
    color: unset;
    text-decoration: none;
    transition: .2s all;

    &:hover {
        color: $highlight;
    }
}