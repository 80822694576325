.right {
    padding:1rem;
    height: 100%;
    display: flex;
    align-items: center;

    .imgWrapper {
        img {
            width: 24rem;
        }

        h3 {
            text-align: right;
        }
    }
}