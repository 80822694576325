.pictureTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
        color:white;
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
        width: 50%;
        text-transform: uppercase;
        font-size: 4rem;
    }
}